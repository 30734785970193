export default {
  id: 'auth-set-new-password',
  context: {
    actionCode: '',
    email: '',
    password: '',
    // repeatPassword: '',
    serviceError: {
      code: '',
      message: '',
    },
  },
  initial: 'verifyPasswordResetCode',
  states: {
    dataEntry: {
      on: {
        PASSWORD_INPUT: {
          actions: 'savePassword',
        },
        PASSWORD_BLUR: [{
          cond: 'passwordIsEmpty',
          target: 'passwordError.isEmpty',
        }, {
        //   cond: 'passwordFormatIsInvalid',
        //   target: 'passwordError.isInvalidPassword',
        // }, {
        //   cond: 'passwordMismatch',
        //   target: 'passwordError.isMismatch',
        // }, {
        //   cond: 'passwordTooShort',
        //   target: 'passwordError.tooShort',
        // }],
        // REPEAT_PASSWORD_INPUT: {
        //   actions: 'savePassword',
        // },
        // REPEAT_PASSWORD_BLUR: [{
        //   cond: 'passwordIsEmpty',
        //   target: 'passwordError.isEmpty',
        // }, {
        //   cond: 'passwordFormatIsInvalid',
        //   target: 'passwordError.isInvalidPassword',
        // }, {
        //   cond: 'passwordMismatch',
        //   target: 'passwordError.isMismatch',
        // }, {
          cond: 'passwordTooShort',
          target: 'passwordError.tooShort',
        }],
        SUBMIT: [{
          cond: 'passwordTooShort',
          target: 'passwordError.tooShort',
        }, {
          target: 'confirmPasswordReset',
        }],
      },
    },
    verifyPasswordResetCode: {
      invoke: {
        src: 'verifyPasswordResetCode',
        onDone: {
          actions: 'saveEmail',
          target: 'dataEntry',
        },
        onError: [{
          actions: 'saveServiceError',
          target: 'serviceError.invalidResetCode',
        }],
      },
    },
    confirmPasswordReset: {
      invoke: {
        src: 'confirmPasswordReset',
        onDone: {
          target: 'awaitingSignInResponse',
        },
        onError: [{
          actions: 'saveServiceError',
          target: 'serviceError',
        }],
      },
    },
    awaitingSignInResponse: {
      invoke: {
        src: 'signInWithEmailPassword',
        onDone: {
          target: 'signedIn',
        },
        onError: [{
          actions: 'saveServiceError',
          target: 'serviceError',
        }],
      },
    },
    passwordError: {
      on: {
        PASSWORD_INPUT: {
          actions: 'savePassword',
          target: 'dataEntry',
        },
      },
      initial: 'isEmpty',
      states: {
        isEmpty: {},
        tooShort: {},
        // isMismatch: {},
        // isInvalidPassword: {},
        wrongPassword: {},
      },
    },
    serviceError: {
      on: {
        PASSWORD_INPUT: {
          actions: 'savePassword',
          target: 'dataEntry',
        },
        SUBMIT: {
          target: 'confirmPasswordReset',
        },
      },
      states: {
        invalidResetCode: {},
      },
    },
    signedIn: {
      type: 'final',
    },
  },
};
