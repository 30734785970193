<template>
  <AuthForm :machine-config="setNewPasswordMachineConfig" :initial-machine-context="{ actionCode: this.actionCode }"
    type="set-new-password" class="max-w-430 mt-20" @successful-submit="$router.push({ name: 'Orders' })">
    <template #default="{ email }">
      <template v-if="email">
        {{ $t('auth_set_new_password.enter_new_password') }}
        <span class="font-bold">
          {{ email }}
        </span>
        {{ $t('auth_set_new_password.enter_new_password_end') }}
      </template>
      <template v-else>
        {{ $t('auth_set_new_password.enter_new_password_again') }}
      </template>
    </template>
    <template #button-primary>
      <IconRefresh class="icon-door-enter h-30 w-30 mr-10" />
      {{ $t('generic_crud_labels.save') }}
    </template>
    <template #button-secondary>
      {{ $t('auth_new_password.to_login') }}
    </template>
  </AuthForm>
</template>


<script>
import IconRefresh from '@/components/icon/IconRefresh';
import AuthForm from '@/components/AuthForm';
import setNewPasswordMachine from '@/machines/setNewPasswordMachine';


export default {
  props: {
    actionCode: {
      type: String,
      default: '',
    },
  },
  components: {
    IconRefresh,
    AuthForm,
  },
  data: () => ({
    setNewPasswordMachineConfig: Object.freeze(setNewPasswordMachine),
  }),
};
</script>
